import { asyncStacktrace } from "auto-trace";
import Auth from "cp-client-auth!sofe";
import { of, throwError } from "rxjs";
import { first, catchError } from "rxjs/operators";

export default function (sessionIsValid) {
  if (sessionIsValid) {
    // In the case that the user is logged in we need to return a proise
    // that will eventually yield the user object as our BlockingPromise
    return Auth.getTenantAsObservable()
      .pipe(
        first(),
        catchError(
          asyncStacktrace((err) => {
            if (err.status === 401) {
              // User might not be logged in
              return of({ noUserYet: true });
            } else {
              return throwError(err);
            }
          })
        )
      )
      .toPromise();
  } else {
    // If the user isn't logged in we need to return an immediately resolved
    // promise as the blockingPromise so that we can continue onward and
    // eventually call SingleSpa.start() so the login page can render
    return Promise.resolve();
  }
}
