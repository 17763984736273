import { asyncStacktrace, catchSyncStacktrace } from "auto-trace";
import canopyUrls from "canopy-urls!sofe";
import Auth from "cp-client-auth!sofe";
import { captureException } from "error-logging!sofe";
import { zip } from "rxjs";

export default function setupRetain() {
  const env = canopyUrls.getEnvironment();

  if (
    !localStorage.getItem("no-product-tracking") &&
    (env === canopyUrls.PRODUCTION_ENVIRONMENT ||
      env === canopyUrls.STAGING_ENVIRONMENT ||
      env === canopyUrls.INTEGRATION_ENVIRONMENT)
  ) {
    zip(
      Auth.getLoggedInUserAsObservable(),
      Auth.getTenantAsObservable()
    ).subscribe(
      (values) => {
        try {
          const [loggedInUser, tenant] = values;
          if (
            loggedInUser.role === "TeamMember" &&
            !loggedInUser.permissions.client
          ) {
            (function (i, s, o, g, r, a, m) {
              i[o] =
                i[o] ||
                function () {
                  (i[o].q = i[o].q || []).push(arguments);
                };
              a = s.createElement(g);
              m = s.getElementsByTagName(g)[0];
              a.async = 1;
              a.src = r + "?auth=ee6b73c4b3b2fa91b634192219c1f519";
              m.parentNode.insertBefore(a, m);
            })(
              window,
              document,
              "profitwell",
              "script",
              "https://public.profitwell.com/js/profitwell.js"
            );

            window.profitwell("start", {
              auth_token: "ee6b73c4b3b2fa91b634192219c1f519",
              user_email: loggedInUser.email,
              user_id: tenant.zuora_id,
            });
          }
        } catch (e) {
          captureException(e);
        }
      },
      asyncStacktrace((err) => {
        err.showToast = false;
        catchSyncStacktrace(err);
      })
    );
  }
}
