import { asyncStacktrace } from "auto-trace";
import Auth, { getTenant } from "cp-client-auth!sofe";
import { of, throwError } from "rxjs";
import { first, catchError } from "rxjs/operators";
import { onPusher } from "fetcher!sofe";

export default function (sessionIsValid) {
  return Promise.resolve().then(() => {
    if (sessionIsValid) {
      subscribeToTenant(getTenant());

      // In the case that the user is logged in we need to return a proise
      // that will eventually yield the user object as our BlockingPromise
      return Auth.getLoggedInUserAsObservable()
        .pipe(
          first(),
          catchError(
            asyncStacktrace((err) => {
              if (err.status === 401) {
                // User might not be logged in
                return of({ noUserYet: true });
              } else {
                return throwError(err);
              }
            })
          )
        )
        .toPromise();
    } else {
      // If the user isn't logged in we need to return an immediately resolved
      // promise as the blockingPromise so that we can continue onward and
      // eventually call SingleSpa.start() so the login page can render
      return Promise.resolve();
    }
  });
}

onPusher("role-updates").subscribe(() => {
  window.location.reload();
});

// This is fired off when a user disconnects from their third party integration (QBO)
const subscribeToTenant = (tenant) => {
  onPusher(
    "third_party_connection_change",
    `private-tenant-${tenant.id}`
  ).subscribe(() => {
    Auth.refetchTenant(true).catch(
      catchError((err, throwError) => {
        if (err && err.status === 401) {
          // ignore
        } else {
          throwError(err);
        }
      })
    );
    Auth.refreshAuthToken();
  });
};
