import isUserLoggedIn from "./is-user-logged-in.bootstrap.js";
import {
  mainLoginPages,
  onLoginPage,
  hashPrefix,
} from "../child-app-active.functions.js";
import Auth, { isWithinLicenseLimit, hasAccess } from "cp-client-auth!sofe";
import { combineLatest } from "rxjs";
import { first } from "rxjs/operators";

let currentTenant;
let currentLoggedInUser;

window.addEventListener("single-spa:routing-event", checkRootAppRedirect);

export default function () {
  return isUserLoggedIn().then((sessionIsValid) => {
    if (sessionIsValid) {
      return Promise.all([
        Auth.getLoggedInUserAsObservable().pipe(first()).toPromise(),
        Auth.getTenantAsObservable().pipe(first()).toPromise(),
      ])
        .then(([user, tenant]) => {
          currentLoggedInUser = user;
          currentTenant = tenant;
        })
        .then(checkLicenses);
    } else {
      // The user is not logged in
      return Promise.all([
        Promise.resolve().then(() => {
          // Redirect to login page, but only if they aren't already on a login page
          if (!mainLoginPages(window.location)) {
            const encodedUrl = window.encodeURIComponent(window.location.href);
            const isMobileBrowser = /Mobi|Android/i.test(navigator.userAgent);
            const hash = window.location.hash;
            const params =
              hash === "" || hash === "#" || hash === "#/"
                ? ""
                : `?redirect_url=${encodedUrl}`;
            if (isMobileBrowser) {
              window.location.replace(`m/login${params}`);
            } else {
              window.location.assign(`/#/login${params}`);
            }
          }
        }),
        Promise.resolve().then(() => {
          const userAndTenant$ = combineLatest(
            Auth.getLoggedInUserAsObservable(),
            Auth.getTenantAsObservable()
          );

          userAndTenant$.subscribe(([user, tenant]) => {
            currentLoggedInUser = user;
            currentTenant = tenant;
            checkLicenses();
          });
        }),
      ]);
    }
  });
}

function checkLicenses() {
  if (
    !currentLoggedInUser ||
    !currentTenant ||
    mainLoginPages(window.location)
  ) {
    return;
  }

  const invalidLicenses = getInvalidLicenses(
    currentTenant,
    currentLoggedInUser
  );
  const onLicenseManagementPage = hashPrefix(
    window.location,
    "global-settings/account-management"
  );
  const hasClosedModal = !!window.localStorage.getItem(
    "global-settings:license-paywall-modal-closed"
  );

  if (invalidLicenses && !onLicenseManagementPage && !hasClosedModal) {
    // remove the node-bootstrap-server loader
    const loader = document.querySelector(
      ".initial-node-bootstrap-server-loader"
    );
    if (loader) {
      loader.remove();
    }

    return SystemJS.import("global-settings!sofe").then((settings) =>
      settings.getLicensePaywallModal(invalidLicenses, checkRootAppRedirect)
    );
  }

  checkRootAppRedirect();
}

/**
 * If we are at the root route, redirect the user to the root app according to their permissions
 */
function checkRootAppRedirect() {
  if (!currentLoggedInUser || !currentTenant) {
    return;
  }
  const hash = window.location.hash;

  if (
    currentLoggedInUser.role.toLowerCase() === "client" &&
    !onLoginPage(window.location)
  ) {
    // Client portal was moved from /# to /m
    // These users should be redirected before anything else has a chance to load.
    return window.location.assign(`${window.location.origin}/m/clients`);
  } else {
    if (hash === "" || hash === "#" || hash === "#/") {
      const { app_dashboard, clients } = currentLoggedInUser.permissions;
      if (app_dashboard) {
        // App dashboard ui (owns the /home route) handles rerouting based on permissions
        window.location.hash = "#/home";
      } else if (clients) {
        window.location.hash = "#/clients";
      } else {
        window.location.hash = "#/transcripts/list";
      }
    }
  }
}

function getInvalidLicenses(tenant, user) {
  if (
    !hasAccess(user)("company_settings_account_management") ||
    user.role === "Client"
  ) {
    // User doesn't have the correct permissions, so don't check licenses
    return;
  }

  const licenses = { ...tenant.licenses };

  const invalidLicenseNames = Object.keys(licenses).filter(
    (type) =>
      !isWithinLicenseLimit(
        type,
        licenses,
        tenant.client_limit,
        tenant.free_transcripts
      )
  );

  if (!invalidLicenseNames.length) return;

  const invalidLicenses = invalidLicenseNames.reduce(
    (invalidLicenses, licenseName) => {
      return {
        ...invalidLicenses,
        [licenseName]: licenses[licenseName],
      };
    },
    {}
  );

  return invalidLicenses;
}
