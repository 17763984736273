import * as isActive from "../child-app-active.functions.js";
import canopyUrls from "canopy-urls!sofe";

export default function appTitleListener() {
  if (
    canopyUrls.getBrandingEnvironment() === canopyUrls.WHITELABEL_ENVIRONMENT
  ) {
    // this will be taken care of in white-label-init.js in canopy-styleguide
    return;
  }
  window.addEventListener("single-spa:routing-event", appTitleListener);

  function updateTitle(title) {
    document.title = "Canopy - " + title;
  }

  function appTitleListener() {
    const location = window.location;
    if (isActive.appDashboardUI(location)) {
      updateTitle("Home");
    } else if (isActive.canopyAdminUI(location)) {
      updateTitle("Admin");
    } else if (isActive.communicationsUI(location)) {
      updateTitle("Communication");
    } else if (isActive.tasksUI(location)) {
      updateTitle("Work");
    } else if (isActive.docsUI(location)) {
      updateTitle("Files");
    } else if (isActive.notesUI(location)) {
      updateTitle("Notes");
    } else if (isActive.engagementsUI(location)) {
      updateTitle("Engagements");
    } else if (isActive.resolutionCasesUI(location)) {
      updateTitle("Resolution Cases");
    } else if (isActive.taxPrepUI(location)) {
      updateTitle("Organizers");
    } else if (isActive.transcriptsUI(location)) {
      updateTitle("Transcripts");
    } else if (
      isActive.billingUI(location) &&
      (isActive.hashPrefix(location, "time") ||
        isActive.containsRoutes(location, "#/client/:id/time"))
    ) {
      updateTitle("Time");
    } else if (isActive.billingUI(location)) {
      updateTitle("Billing");
    } else if (isActive.lettersUI(location)) {
      updateTitle("Letters");
    } else if (isActive.noticesUI(location)) {
      updateTitle("Notices");
    } else if (isActive.notificationsUI(location)) {
      updateTitle("Notifications");
    } else if (isActive.clientMenu(location)) {
      updateTitle("Client");
    } else if (isActive.clientsUI(location)) {
      if (location.hash.includes("contacts")) {
        updateTitle("Contacts");
      } else {
        updateTitle("Clients");
      }
    } else if (isActive.calendarUI(location)) {
      updateTitle("Calendar");
    } else if (isActive.activityLogReportingUI(location)) {
      updateTitle("Activity");
    } else if (isActive.insightsReportingUI(location)) {
      updateTitle("Insights");
    } else if (isActive.globalSettings(location)) {
      updateTitle("Settings");
    } else if (isActive.prototypesUI(location)) {
      updateTitle("Prototypes");
    } else if (isActive.mainLoginPages(location)) {
      updateTitle("Login");
    } else {
      document.title = "Canopy: Delightful Client Management";
    }
  }
}
