import delightedSurveyBootstrap from "./delighted-survey.bootstrap";
import intercomBootstrap from "./intercom.bootstrap.js";
import retainBootstrap from "./retain.bootstrap.js";
import sentryBootstrap from "./sentry.bootstrap.js";
import sofeInspectorBootstrap from "./sofe-inspector.bootstrap.js";
import gapiBootstrap from "./gapi.bootstrap.js";
import rootRouteBootstrap from "./root-route.bootstrap.js";
import perfMetricsBootstrap, {
  waitForEvent,
} from "./perf-metrics.bootstrap.js";
import authInfoBootstrap from "./auth-info.bootstrap.js";
import { fullStoryIdentify } from "./full-story-identify.bootstrap";
import "./globalizer.helper.js";
import { onLogoutPage } from "../child-app-active.functions.js";
import { dataDogRum } from "./data-dog-rum.bootstrap";
import mailtoListener from "./mailto-listener.bootstrap.js";
import appTitleListener from "./app-title-listener.bootstrap";
import bannerNotificationsBootstrap from "./banner-notifications.bootstrap";

import { catchSyncStacktrace, syncStacktrace } from "auto-trace";

const appMounted = waitForEvent("single-spa:first-mount");

export default function bootstrapSpa() {
  if (onLogoutPage(window.location)) {
    // set listener on hash change so that when we load a page besides logout
    // we can start start the normal boostrap promises
    window.addEventListener(
      "single-spa:routing-event",
      logoutPageEventListener
    );
    // If we're on the logout page we want to call the callback (which eventually singleSpa.start())
    // WITHOUT running any of the bootstrap promises
    return Promise.resolve();
  } else {
    // Assuming we're not initializing on a page besides the logout page we want
    // to call all the boostrap promises both blocking and non-blocking
    return bootstrapPromises();
  }

  function logoutPageEventListener() {
    // An event listener that will call the bootstrap promises when the page isn't /#/logout
    if (!onLogoutPage(window.location)) {
      // removing the event listener. We never want to fire the bootstrap methods twice
      window.removeEventListener(
        "single-spa:routing-event",
        logoutPageEventListener
      );
      bootstrapPromises().catch((err) => {
        catchSyncStacktrace(err);
      });
    }
  }
}

function bootstrapPromises() {
  let appFailure = false;

  const blockingPromises = [authInfoBootstrap(), rootRouteBootstrap()];

  // non-blocking bootstraps
  sentryBootstrap();
  sofeInspectorBootstrap();
  gapiBootstrap();
  perfMetricsBootstrap();
  delightedSurveyBootstrap();
  dataDogRum();
  mailtoListener();
  appTitleListener();
  bannerNotificationsBootstrap();

  // Third party tracking bootstraps - deferred and nonblocking
  appMounted.then(() =>
    new Promise((resolve) => setTimeout(resolve, 3000)).then(() =>
      Promise.all([fullStoryIdentify(), intercomBootstrap(), retainBootstrap()])
    )
  );

  // blocking bootstraps
  return Promise.all(blockingPromises).catch((err) => {
    appFailure = true;
    err = syncStacktrace(err);
    err.message = "Unable to bootstrap app\n" + err.message;

    const throwError = () => {
      throw err;
    };
    return sentryBootstrap() // Make sure sentry is initialized
      .then(throwError)
      .catch(throwError); // Sentry will be initialized even in an error caused by loggedInUser failing
  });
}
