import React, { lazy, Suspense } from "react";
import { createRoot } from "react-dom/client";
import Auth, { hasAccess } from "cp-client-auth!sofe";

const ComposeEmail = lazy(() =>
  SystemJS.import("communications-ui!sofe").then((communications) =>
    communications.getComposeEmailOverlay()
  )
);

export default function mailtoListener() {
  // track modal open state to prevent multiple modals from opening and to prevent
  // nested modals if a mailto link is added to the modal's Rich Text Editor
  let modalOpen = false;
  Auth.getLoggedInUserAsObservable().subscribe((loggedInUser) => {
    function clickListener(event) {
      const canUserSendEmails =
        loggedInUser.role !== "Client" &&
        hasAccess(loggedInUser)("communications");

      if (!modalOpen && canUserSendEmails) {
        const mailto = event?.target?.closest?.("a[href^=mailto]");
        if (mailto) {
          event.preventDefault();

          modalOpen = true;

          const email = new URL(mailto.href).pathname;
          const container = document.createElement("div");
          document.body.appendChild(container);
          const root = createRoot(container);

          root.render(
            <Suspense fallback={<div />}>
              <ComposeEmail
                to={email}
                close={() => {
                  modalOpen = false;
                  root.unmount(container);
                }}
                source="Email Hyperlink"
              />
            </Suspense>,
            container
          );
        }
      }
    }

    window.addEventListener("click", clickListener, true);

    return () => {
      modalOpen = false;
      window.removeEventListener("click", clickListener, true);
    };
  });
}
