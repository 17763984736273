import React, { lazy, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { catchError } from "auto-trace";
import Auth from "cp-client-auth!sofe";

const BannerNotificationsController = lazy(() =>
  SystemJS.import("notifications-ui!sofe").then((notificationsUi) =>
    notificationsUi.getBannerNotificationsController()
  )
);

export default function bannerNotificationsBootstrap() {
  const container = document.createElement("div");
  container.id = "banner-notifications-container";
  document.body.appendChild(container);

  let root; // TODO: lets move the loggedInUser check to the notifications controller
  Auth.getLoggedInUserAsObservable().subscribe(
    (loggedInUser) => {
      if (loggedInUser.role !== "Client") {
        root?.unmount?.();
        root = createRoot(container);

        root.render(
          <Suspense fallback={<div />}>
            <BannerNotificationsController />
          </Suspense>
        );
      }
    },
    catchError((error, throwError) => {
      error.showToast = false;
      throwError(error);
    })
  );
}
